import React, { } from 'react';
import { motion } from 'framer-motion';
import { TrendingUp, BarChart, Target, Users, FileText, BookOpen, Briefcase, Zap, MessageCircle, BarChart2, ChevronRight} from 'lucide-react';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const Button = ({ icon: Icon, text, onClick }) => (
  <motion.button
    onClick={onClick}
    className="bg-blue-500/80 hover:bg-blue-600 text-white font-medium py-2.5 px-5 rounded-lg flex items-center gap-2 backdrop-blur-sm shadow-lg shadow-blue-500/20 transition-all"
    whileHover={{ scale: 1.02, boxShadow: "0 8px 30px rgba(59, 130, 246, 0.2)" }}
    whileTap={{ scale: 0.98 }}
  >
    <Icon className="w-5 h-5" />
    <span className="text-base">{text}</span>
  </motion.button>
);


const DeliverableItem = ({ icon: Icon, title, description }) => (
  <motion.div
    className="bg-gray-800 rounded-xl p-6 flex items-start"
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
    viewport={{ once: true }}
  >
    <div className="flex-shrink-0 mr-4">
      <Icon className="w-6 h-6 text-blue-500" />
    </div>
    <div>
      <h4 className="text-xl font-semibold mb-2 text-blue-400">{title}</h4>
      <p className="text-gray-white">{description}</p>
    </div>
  </motion.div>
);

const TimelineEvent = ({ year, title, description }) => (
  <div className="flex">
    <div className="flex flex-col items-center mr-4">
      <div className="flex items-center justify-center w-8 h-8 bg-blue-500 rounded-full">
        <ChevronRight className="w-5 h-5 text-white" />
      </div>
      <div className="w-px h-full bg-blue-500"></div>
    </div>
    <div className="pb-8">
      <p className="mb-2 text-xl font-bold text-blue-500">{year}</p>
      <h3 className="text-xl font-semibold text-white mb-2">{title}</h3>
      <p className="text-gray-400">{description}</p>
    </div>
  </div>
);

const MarketingServicesPage = () => {
  const navigate = useNavigate();

  const EcosystemFeature = ({ title, description, imageUrl, isReversed }) => (
    <motion.div
      className={`flex flex-col ${isReversed ? 'md:flex-row-reverse' : 'md:flex-row'} items-center gap-8 mb-20`}
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      viewport={{ once: true }}
    >
      <div className="md:w-1/2">
        <img src={imageUrl} alt={title} className="rounded-lg w-full" />
      </div>
      <div className="md:w-1/2">
        <h3 className="text-2xl font-bold text-blue-400 mb-4">{title}</h3>
        <p className="text-gray-300 mb-4">{description}</p>
        <div className="flex items-center text-blue-500">
        </div>
      </div>
    </motion.div>
  );

  const services = [
    { icon: TrendingUp, title: "Strategy Development", description: "Customized marketing strategies tailored to your book and target audience." },
    { icon: BarChart, title: "Performance Analytics", description: "In-depth analysis of your marketing efforts and book sales." },
    { icon: Target, title: "Audience Targeting", description: "Knowing your Audience id critical. Identify and reach your ideal readers effectively." },
    { icon: Users, title: "Brand Development", description: "Create a strong, cohesive author brand across all platforms." },
    { icon: FileText, title: "Content Creation", description: "Engaging copy for ads, social media, and promotional materials." },
    { icon: BookOpen, title: "Campaign Execution", description: "Full implementation of marketing campaigns and promotions." },
  ];

  const ServiceBox = ({ title, description, icon: Icon, index }) => {
    return (
      <motion.div
        className="bg-gray-800/50 backdrop-blur-sm rounded-xl p-6 border border-gray-700/50 hover:border-blue-500/30 transition-all"
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        whileHover={{ y: -5 }}
        transition={{ duration: 0.5, delay: index * 0.1 }}
        viewport={{ once: true }}
      >
        <div className="flex flex-col items-start">
          <div className="bg-blue-500/10 p-3 rounded-lg mb-4">
            <Icon className="w-6 h-6 text-blue-400" />
          </div>
          <h3 className="text-xl font-semibold text-white mb-2">{title}</h3>
          <p className="text-gray-300 leading-relaxed">{description}</p>
        </div>
      </motion.div>
    );
  };

  return (
    <div className="bg-gray-900 text-white min-h-screen">
      <Helmet>
        <title>Book Marketing Services - Kemah Bay Marketing</title>
        <meta name="description" content="Strategic book marketing services to help authors reach their target audience. Social media, email campaigns, and promotional strategies." />
        <meta property="og:title" content="Book Marketing Services - Kemah Bay Marketing" />
        <meta property="og:url" content="https://www.kemahbay.com/Marketing" />
        <link rel="canonical" href="https://www.kemahbay.com/Marketing" />
      </Helmet>
      <main>
      <section className="relative h-screen flex items-center justify-center">
          <div className="absolute inset-0 bg-black opacity-40 z-10"></div>
          <video
            autoPlay
            loop
            muted
            playsInline
            className="absolute inset-0 w-full h-full object-cover pointer-events-none"
          >
            <source src={process.env.PUBLIC_URL + '/resources/184489-873483996_medium.mp4'} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="relative z-20 text-center flex flex-col items-center">
            <motion.h1
              className="text-5xl font-bold mb-6"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
            >
              Digital and Physical Marketing Solutions
            </motion.h1>
            <motion.p
              className="text-xl mb-8"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
            >
              Tailored marketing strategies to boost your book's visibility and reach.
            </motion.p>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.4 }}
            >          
              <Button 
                icon={Briefcase} 
                text="Get Your Customized Marketing Plan" 
                onClick={() => navigate('/MarketingQuotePage')}
              />
            </motion.div>
          </div>
        </section>

        <section className="bg-gray-800 py-20">
          <div className="container mx-auto px-4">
            <motion.h2
              className="text-3xl font-bold mb-10 text-center"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: true }}
            >
              Our Marketing Services
            </motion.h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {services.map((service, index) => (
                <ServiceBox key={index} {...service} index={index} />
              ))}
            </div>
          </div>
        </section>

        <section className=" py-20">
          <div className="container mx-auto px-4 text-xl">
            <motion.h2
              className="text-3xl font-bold mb-16 text-center"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: true }}
            >
              Marketing Ecosystem for Authors
            </motion.h2>
            
            <EcosystemFeature
              title="Create and Deploy Our Strategy"
              description="We will help you create and deploy a customized marketing plan that caters directly to your target audience and the platforms that they use."
              icon={Zap}
              imageUrl="/resources/email-3249062_1920.png"
              isReversed={false}
            />

            <EcosystemFeature
              title="Get More From Your Marketer"
              description="Allow us to strategize, plan, and execute! We will do all of the scheduling, planning, and heavy lifting. Allow us to help you get your message out on multiple platforms using video, text, images, all catered to your writing style and book."
              icon={MessageCircle}
              imageUrl="/resources/digital-marketing-1527799.png"
              isReversed={true}
            />

            <EcosystemFeature
              title="Get Customized Advertising Insights"
              description="We provide our clients with monthly or quarterly marketing reports so you can see the sucess of your campeigns. Get breakdowns of key demographics information, conversion rate, and more!"
              icon={BarChart2}
              imageUrl="/resources/computer-7339324_1920.png"
              isReversed={false}
            />
          </div>
        </section>

        <section className="bg-gray-800 container mx-auto px-4 py-20 rounded-xl">
          <motion.h2
            className="text-3xl font-bold mb-8 text-center"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
          >
            What You Can Expect
          </motion.h2>
          <div className="grid md:grid-cols-2 gap-8 text-lg rounded-xl">
            <DeliverableItem 
              icon={TrendingUp}
              title="Customized Marketing Strategy"
              description="A detailed plan outlining your unique marketing approach, including targeted channels, content strategy, and promotional timelines."
            />
            <DeliverableItem 
              icon={BarChart}
              title="Performance Analytics"
              description="Regular reports on key performance indicators, sales trends, and ROI analysis to track the effectiveness of your marketing efforts."
            />
            <DeliverableItem 
              icon={Target}
              title="Audience Targeting Insights"
              description="In-depth analysis of your target readership, including demographics, preferences, and buying behaviors to refine your marketing approach."
            />
            <DeliverableItem 
              icon={Users}
              title="Brand Development"
              description="Cohesive author branding across all platforms, including social media profiles, author website, and promotional materials."
            />
          </div>
        </section>

        <section className="container mx-auto px-4 py-20">
          <motion.h2
            className="text-3xl font-bold mb-8 text-center"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
          >
            Our Approach
          </motion.h2>
          <div className="grid md:grid-cols-2 gap-8 text-lg">
            <motion.div
              className="bg-gray-800 rounded-lg shadow-lg p-6"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              viewport={{ once: true }}
            >
              <h3 className="text-xl font-semibold mb-4 text-blue-400">Tailored Strategies</h3>
              <p className="mb-4">Each genre, category, and even individual novel requires a careful analysis and attention to detail in order to develop an effective promotional strategy.</p>
              <p>As with a complex plot, there are numerous moving parts involved in successful marketing. An author's website should coordinate with any cover theme, which in turn should be in lockstep with social media profiles. A concurrent, cooperative effort leads to branding that is both memorable and effective.</p>
            </motion.div>
            <motion.div
              className="bg-gray-800 rounded-lg shadow-lg p-6"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              viewport={{ once: true }}
            >
              <h3 className="text-xl font-semibold mb-4 text-blue-400">Comprehensive Services</h3>
              <p className="mb-4">Often, authors prefer to spend their time writing that next book rather than trying to crop a photograph to the right size for a media post. That's where we come in.</p>
              <p>We handle everything from strategy development to content creation and campaign execution, allowing you to focus on what you do best - writing amazing books.</p>
            </motion.div>
          </div>
        </section>

        <section className="bg-gray-800 py-16">
          <div className="container mx-auto px-4">
            <motion.h2
              className="text-3xl font-bold text-center mb-10"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: true }}
            >
              Evolution of Author Marketing
            </motion.h2>
            <div className="max-w-3xl mx-auto text-lg">
              <TimelineEvent
                year="2000s"
                title="Traditional Media Dominance"
                description="Book tours, print ads, and TV appearances were the primary marketing channels for authors."
              />
              <TimelineEvent
                year="2010s"
                title="Rise of Social Media"
                description="Authors began leveraging platforms like Facebook and Twitter to connect directly with readers."
              />
              <TimelineEvent
                year="2015+"
                title="Content Marketing Era"
                description="Blog tours, guest posts, and email newsletters became essential tools for author promotion."
              />
              <TimelineEvent
                year="2020+"
                title="Multi-Channel Digital Strategies"
                description="Advanced targeting, influencer partnerships, and personalized marketing automation drive author success."
              />
              <TimelineEvent
                year="Future"
                title="AI-Powered Personalization"
                description="Predictive analytics and AI-driven content creation will revolutionize how authors connect with their ideal readers."
              />
            </div>
          </div>
        </section>

        <section className="bg-gray-800/50 backdrop-blur-sm py-20">
          <div className="container mx-auto px-4">
            <motion.h2
              className="text-4xl font-bold mb-12 text-center bg-clip-text "
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: true }}
            >
              Frequently Asked Questions
            </motion.h2>
            <div className="grid md:grid-cols-2 gap-6">
              <motion.div
                className="bg-gray-700 rounded-lg shadow-lg p-6"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                viewport={{ once: true }}
              >
                <h3 className="text-xl font-semibold mb-4 text-blue-400">What genres do you work with?</h3>
                <p>We work with authors across all genres, including non-fiction and steamy prose. Our team has experience in crafting marketing strategies for a wide range of book categories.</p>
              </motion.div>
              <motion.div
                className="bg-gray-700 rounded-lg shadow-lg p-6"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                viewport={{ once: true }}
              >
                <h3 className="text-xl font-semibold mb-4 text-blue-400">What's the minimum marketing budget?</h3>
                <p>We typically work with authors who have marketing budgets of at least $500 (USD) monthly. However, we can discuss your specific situation and tailor our services to your needs.</p>
              </motion.div>
              <motion.div
                className="bg-gray-700 rounded-lg shadow-lg p-6"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.4 }}
                viewport={{ once: true }}
              >
                <h3 className="text-xl font-semibold mb-4 text-blue-400">How long does a typical engagement last?</h3>
                <p>The duration of our engagements varies based on your needs and goals. We offer both short-term campaign management and long-term strategic partnerships. We'll discuss the best approach for you during our initial consultation.</p>
              </motion.div>
              <motion.div
                className="bg-gray-700 rounded-lg shadow-lg p-6"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.6 }}
                viewport={{ once: true }}
              >
                <h3 className="text-xl font-semibold mb-4 text-blue-400">How do you measure success?</h3>
                <p>We track various metrics including book sales, Amazon rankings, social media engagement, website traffic, and email list growth. We provide regular reports and adjust our strategies based on performance data to ensure the best results for your investment.</p>
              </motion.div>
            </div>
          </div>
        </section>

        <section className="bg-gray-800 py-20">
          <div className="container mx-auto px-4">
            <motion.div
              className="rounded-lg shadow-lg p-8 text-center bg-slate-600"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: true }}
            >
              <h2 className="text-3xl font-bold mb-4">Ready to Elevate Your Author Brand?</h2>
              <p className="text-xl mb-6">Let's create a marketing strategy that's as unique as your writing.</p>
              <div className="flex justify-center space-x-4">
                <Button icon={Briefcase} text="Get A Quote" onClick={() => navigate('/MarketingQuotePage')} />
                <Button icon={MessageCircle} text="Contact Us" onClick={() => navigate('/Contact')} />
              </div>
            </motion.div>
          </div>
        </section>
      </main>

      <Footer />
    </div>
  );
};

export default MarketingServicesPage;
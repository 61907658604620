import React from 'react';
import { Helmet } from 'react-helmet-async';

const SEOHead = () => {
  return (
    <Helmet>
      {/* Essential Meta Tags */}
      <title>Kemah Bay Marketing - Professional Book Publishing & Author Services</title>
      <meta name="description" content="Transform your manuscript into a published book with Kemah Bay Marketing. Expert editing, publishing, and marketing services helping authors achieve $7M+ in revenue through 600+ successful publications." />
      
      {/* OpenGraph Meta Tags */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Kemah Bay Marketing - Professional Book Publishing & Author Services" />
      <meta property="og:description" content="Expert book publishing services for independent authors. From manuscript to marketing, we help authors achieve publishing success with proven results." />
      <meta property="og:url" content="https://www.kemahbay.com" />
      <meta property="og:site_name" content="Kemah Bay Marketing" />
      
      {/* Twitter Card Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Kemah Bay Marketing - Book Publishing Services" />
      <meta name="twitter:description" content="Professional publishing services helping independent authors succeed. Expert editing, marketing, and distribution services." />
      
      {/* Canonical URL */}
      <link rel="canonical" href="https://www.kemahbay.com" />
      
      {/* WWW Redirect Script */}
      <script type="text/javascript">
        {`
          if (window.location.hostname === 'kemahbay.com') {
            window.location.replace('https://www.kemahbay.com' + window.location.pathname);
          }
        `}
      </script>

      {/* Structured Data */}
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "PublishingService",
            "name": "Kemah Bay Marketing",
            "description": "Professional book publishing and marketing services for independent authors",
            "url": "https://www.kemahbay.com",
            "areaServed": "Worldwide",
            "potentialAction": {
              "@type": "PublishAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://www.kemahbay.com/contact",
                "actionPlatform": [
                  "http://schema.org/DesktopWebPlatform",
                  "http://schema.org/MobileWebPlatform"
                ]
              }
            }
          }
        `}
      </script>
    </Helmet>
  );
};

export default SEOHead;
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Send, Loader, CheckCircle, XCircle } from 'lucide-react';
import { Helmet } from 'react-helmet-async';

const MarketingQuotePage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    bookTitle: '',
    genre: '',
    publishStatus: '',
    marketingBudget: '',
    services: [],
    additionalInfo: ''
  });

  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState({ text: '', type: '' });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setFormData(prev => ({
        ...prev,
        services: checked
          ? [...prev.services, value]
          : prev.services.filter(service => service !== value)
      }));
    } else {
      setFormData(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage({ text: '', type: '' });
  
    // Form validation
    if (!formData.name || !formData.email || !formData.bookTitle) {
      setMessage({ 
        text: 'Please fill out all required fields', 
        type: 'error' 
      });
      setIsLoading(false);
      return;
    }
  
    if (formData.services.length === 0) {
      setMessage({ 
        text: 'Please select at least one service', 
        type: 'error' 
      });
      setIsLoading(false);
      return;
    }
  
    try {
      const response = await fetch('https://kemahbay.netlify.app/.netlify/functions/submit-marketing', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      const data = await response.json();
  
      if (data.success) {
        setMessage({
          text: data.message || 'Quote request submitted successfully!',
          type: 'success'
        });
  
        // Reset form
        setFormData({
          name: '',
          email: '',
          bookTitle: '',
          genre: '',
          publishStatus: '',
          marketingBudget: '',
          services: [],
          additionalInfo: ''
        });
  
        // Scroll to top to show success message
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } else {
        throw new Error(data.message || 'Submission failed');
      }
    } catch (error) {
      console.error('Submission error:', error);
      setMessage({
        text: error.message || 'An error occurred. Please try again.',
        type: 'error'
      });
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-gray-900 text-white min-h-screen py-12">
      <Helmet>
        <title>Get a Marketing Quote - Kemah Bay Marketing</title>
        <meta name="description" content="Request a customized marketing quote for your book. Professional marketing strategies tailored to your needs." />
        <meta property="og:title" content="Marketing Quote - Kemah Bay Marketing" />
        <meta property="og:url" content="https://www.kemahbay.com/MarketingQuotePage" />
        <link rel="canonical" href="https://www.kemahbay.com/MarketingQuotePage" />
      </Helmet>
      <div className="container mx-auto px-4">
        <motion.h1
          className="text-4xl font-bold mb-8 text-center"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Get Your Customized Marketing Quote
        </motion.h1>
        
        {message.text && (
          <motion.div
            className={`mb-6 p-4 rounded-lg shadow-lg ${
              message.type === 'success' 
                ? 'bg-green-500/20 text-green-200 border border-green-500' 
                : 'bg-red-500/20 text-red-200 border border-red-500'
            }`}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
          >
            <div className="flex items-center">
              {message.type === 'success' ? (
                <CheckCircle className="w-5 h-5 mr-2" />
              ) : (
                <XCircle className="w-5 h-5 mr-2" />
              )}
              {message.text}
            </div>
          </motion.div>
        )}
        
        <motion.form
          onSubmit={handleSubmit}
          className="max-w-2xl mx-auto bg-gray-800 p-8 rounded-lg shadow-lg"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <div className="mb-4">
            <label htmlFor="name" className="block mb-2">Your Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              className="w-full p-2 border rounded text-gray-300"
              required
            />
          </div>

          <div className="mb-4">
            <label htmlFor="email" className="block mb-2">Email Address</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              className="w-full p-2 border rounded text-gray-300"
              required
            />
          </div>

          <div className="mb-4">
            <label htmlFor="bookTitle" className="block mb-2">Book Title</label>
            <input
              type="text"
              id="bookTitle"
              name="bookTitle"
              value={formData.bookTitle}
              onChange={handleInputChange}
              className="w-full p-2 border rounded text-gray-300"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="genre" className="block mb-2">Genre</label>
            <input
              type="text"
              id="genre"
              name="genre"
              value={formData.genre}
              onChange={handleInputChange}
              className="w-full p-2 border rounded text-gray-300"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="publishStatus" className="block mb-2">Publishing Status</label>
            <select
              id="publishStatus"
              name="publishStatus"
              value={formData.publishStatus}
              onChange={handleInputChange}
              className="w-full p-2 border rounded text-gray-300"
            >
              <option value="">Select status</option>
              <option value="unpublished">Not yet published</option>
              <option value="selfPublished">Self-published</option>
              <option value="traditionallyPublished">Traditionally published</option>
            </select>
          </div>

          <div className="mb-4">
            <label htmlFor="marketingBudget" className="block mb-2">Monthly Marketing Budget ($)</label>
            <input
              type="number"
              id="marketingBudget"
              name="marketingBudget"
              value={formData.marketingBudget}
              onChange={handleInputChange}
              className="w-full p-2 border rounded text-gray-300"
              min="0"
              step="100"
            />
          </div>

          <div className="mb-4">
            <label className="block mb-2">Services You're Interested In</label>
            <div className="grid grid-cols-2 gap-2">
              {['Social Media Marketing', 'Email Campaigns', 'Book Launch Strategy', 'Author Website Design', 'Amazon Ads', 'Content Creation'].map((service) => (
                <label key={service} className="flex items-center">
                  <input
                    type="checkbox"
                    name="services"
                    value={service}
                    checked={formData.services.includes(service)}
                    onChange={handleInputChange}
                    className="mr-2"
                  />
                  {service}
                </label>
              ))}
            </div>
          </div>

          <div className="mb-4">
            <label htmlFor="additionalInfo" className="block mb-2">Additional Information</label>
            <textarea
              id="additionalInfo"
              name="additionalInfo"
              value={formData.additionalInfo}
              onChange={handleInputChange}
              className="w-full p-2 border rounded text-gray-300"
              rows="4"
              placeholder="Tell us more about your marketing goals or any specific questions you have."
            ></textarea>
          </div>

          <motion.button
            type="submit"
            className={`bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded flex items-center justify-center w-full ${
              isLoading ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            whileHover={{ scale: isLoading ? 1 : 1.05 }}
            whileTap={{ scale: isLoading ? 1 : 0.95 }}
            disabled={isLoading}
          >
            {isLoading ? (
              <Loader className="animate-spin mr-2" size={20} />
            ) : (
              <Send className="mr-2" size={20} />
            )}
            {isLoading ? 'Submitting...' : 'Get Your Quote'}
          </motion.button>
        </motion.form>
      </div>
    </div>
  );
};

export default MarketingQuotePage;
import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className="min-h-screen w-full bg-[#0B0B1A] flex items-center justify-center overflow-hidden relative">
      {/* Stars background - we'll keep this as a styled component since it needs the background pattern */}
      <div 
        className="absolute inset-0 bg-[radial-gradient(circle_at_center,_transparent_0%,_#0B0B1A_100%)]"
        style={{
          background: `
            radial-gradient(circle at center, transparent 0%, #0B0B1A 100%),
            url("data:image/svg+xml,%3Csvg width='300' height='300' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23FFF'%3E%3Ccircle cx='10' cy='10' r='1'/%3E%3Ccircle cx='50' cy='30' r='1'/%3E%3Ccircle cx='90' cy='60' r='1'/%3E%3Ccircle cx='130' cy='20' r='1'/%3E%3Ccircle cx='170' cy='90' r='1'/%3E%3C/g%3E%3C/svg%3E")
          `,
          animation: 'twinkling 30s linear infinite',
        }}
      />

      {/* Orbiting planets - we'll keep the animations in a style tag */}
      <div className="absolute text-5xl" style={{ animation: 'orbit1 40s linear infinite' }}>🪐</div>
      <div className="absolute text-4xl" style={{ animation: 'orbit2 50s linear infinite' }}>🌍</div>
      <div className="absolute text-3xl" style={{ animation: 'orbit3 60s linear infinite' }}>🌎</div>
      <div className="absolute text-[2.8rem]" style={{ animation: 'orbit4 45s linear infinite' }}>🌏</div>
      <div className="absolute text-3xl" style={{ animation: 'satelliteOrbit 30s linear infinite' }}>🛸</div>

      {/* Main content */}
      <div className="text-center z-10 p-5 max-w-xl">
        <h1 className="text-[8rem] font-bold m-0 text-[#4ECDC4]">404</h1>
        <div className="text-7xl my-8">👨‍🚀</div>
        <div className="text-2xl my-4 text-[#4ECDC4]">Houston, we have a problem!</div>
        <p className="text-white">The page you're looking for seems to have drifted into a black hole.</p>
        <Link 
          to="/" 
          className="inline-block bg-[#4ECDC4] text-white px-6 py-3 rounded-full font-bold mt-8 transition-transform hover:scale-105"
        >
          Return to Mission Control
        </Link>
      </div>

      {/* Required keyframe animations */}
      <style jsx>{`
        @keyframes twinkling {
          from { background-position: 0 0; }
          to { background-position: -10000px 5000px; }
        }

        @keyframes orbit1 {
          from { transform: rotate(0deg) translateX(200px) rotate(0deg); }
          to { transform: rotate(360deg) translateX(200px) rotate(-360deg); }
        }

        @keyframes orbit2 {
          from { transform: rotate(90deg) translateX(300px) rotate(-90deg); }
          to { transform: rotate(450deg) translateX(300px) rotate(-450deg); }
        }

        @keyframes orbit3 {
          from { transform: rotate(180deg) translateX(250px) rotate(-180deg); }
          to { transform: rotate(540deg) translateX(250px) rotate(-540deg); }
        }

        @keyframes orbit4 {
          from { transform: rotate(270deg) translateX(350px) rotate(-270deg); }
          to { transform: rotate(630deg) translateX(350px) rotate(-630deg); }
        }

        @keyframes satelliteOrbit {
          from { transform: rotate(0deg) translateX(150px) rotate(0deg); }
          to { transform: rotate(-360deg) translateX(150px) rotate(360deg); }
        }
      `}</style>
    </div>
  );
};

export default NotFound;
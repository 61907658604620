import React from 'react';
import Footer from './Footer'
import { Helmet } from 'react-helmet-async';

const PrivacyPolicy = () => {
  return (
    
    <div className="min-h-screen bg-gray-900 py-12 px-4 sm:px-6 lg:px-8">
      <Helmet>
        <title>Privacy Policy - Kemah Bay Marketing</title>
        <meta name="description" content="Our privacy policy outlines how we collect, use, and protect your information." />
        <meta property="og:title" content="Privacy Policy - Kemah Bay Marketing" />
        <meta property="og:url" content="https://www.kemahbay.com/Privacy" />
        <link rel="canonical" href="https://www.kemahbay.com/Privacy" />
      </Helmet>
      <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-md p-8">
        <h1 className="text-3xl font-bold text-center text-gray-900 mb-8">Privacy Policy</h1>
        
        <div className="space-y-6 text-gray-700">
          <section>
            <h2 className="text-xl font-semibold text-gray-800 mb-4">Introduction</h2>
            <p className="mb-4">
              Kemah Bay Marketing LLC ("we," "us," or "our") operates the website https://kemahbay.com/ (the "Site"). 
              We are committed to protecting your personal information and your right to privacy. This Privacy Policy 
              outlines how we collect, use, disclose, and safeguard your information when you visit our Site.
            </p>
            <p>
              By accessing or using our Site, you acknowledge that you have read, understood, and agree to be bound 
              by all the terms of this Privacy Policy. If you do not agree with the terms of this Privacy Policy, 
              please do not access the Site.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-800 mb-4">Information We Collect</h2>
            <p className="mb-4">We may collect personal information that you voluntarily provide to us when you:</p>
            <ul className="list-disc pl-6 mb-4 space-y-2">
              <li>Fill out forms on our Site</li>
              <li>Contact us via email or phone</li>
              <li>Subscribe to newsletters or other communications</li>
            </ul>
            <p>The personal information we collect may include:</p>
            <ul className="list-disc pl-6 space-y-2">
              <li>Name</li>
              <li>Email Address</li>
              <li>Phone Number</li>
              <li>Book Details (such as titles, authors, and other related preferences)</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-800 mb-4">How We Use Your Information</h2>
            <p className="mb-4">We use the information we collect for various purposes, including:</p>
            <ul className="list-disc pl-6 space-y-2">
              <li className="font-medium">To Provide and Maintain Our Services: <span className="font-normal">To facilitate account creation, authenticate users, and provide you with requested services.</span></li>
              <li className="font-medium">To Communicate With You: <span className="font-normal">To respond to your inquiries, send newsletters, marketing materials, and other information that may be of interest to you.</span></li>
              <li className="font-medium">To Improve Our Site: <span className="font-normal">To understand how our services are used and to improve them accordingly.</span></li>
              <li className="font-medium">To Comply With Legal Obligations: <span className="font-normal">To enforce our terms, conditions, and policies for business purposes, legal reasons, and contractual obligations.</span></li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-800 mb-4">Legal Basis for Processing</h2>
            <p className="mb-4">We process your personal information based on the following legal grounds:</p>
            <ul className="list-disc pl-6 space-y-2">
              <li><span className="font-medium">Consent:</span> When you have given explicit consent for us to process your personal information for a specific purpose.</li>
              <li><span className="font-medium">Contractual Necessity:</span> When processing is necessary to perform a contract with you.</li>
              <li><span className="font-medium">Legal Obligations:</span> When processing is necessary for compliance with a legal obligation.</li>
              <li><span className="font-medium">Legitimate Interests:</span> When processing is necessary for our legitimate interests and does not override your data protection rights.</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-800 mb-4">Disclosure of Your Information</h2>
            <p className="mb-4">We may share your personal information in the following situations:</p>
            <ul className="list-disc pl-6 space-y-2">
              <li><span className="font-medium">With Service Providers:</span> We may share your information with third-party vendors, consultants, and service providers who perform services on our behalf.</li>
              <li><span className="font-medium">For Legal Reasons:</span> If required to do so by law or in response to valid requests by public authorities.</li>
              <li><span className="font-medium">Business Transfers:</span> In connection with any merger, sale of company assets, financing, or acquisition of all or a portion of our business.</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-800 mb-4">Data Security</h2>
            <p>
              We implement reasonable administrative, technical, and physical security measures to protect your personal information. 
              However, please note that no method of transmission over the Internet or electronic storage is completely secure.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-800 mb-4">Cookies and Tracking Technologies</h2>
            <p className="mb-4">
              We use cookies and similar tracking technologies to track the activity on our Site and hold certain information. 
              Cookies are files with a small amount of data that may include an anonymous unique identifier.
            </p>
            <p>
              You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. 
              However, some parts of our Site may not function properly if you disable cookies.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-800 mb-4">Third-Party Links</h2>
            <p>
              Our Site may contain links to third-party websites or services that are not owned or controlled by us. 
              We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-800 mb-4">Your Rights</h2>
            <p className="mb-4">Depending on your jurisdiction, you may have the following data protection rights:</p>
            <ul className="list-disc pl-6 space-y-2">
              <li><span className="font-medium">Access:</span> The right to access and receive a copy of the personal information we hold about you.</li>
              <li><span className="font-medium">Rectification:</span> The right to correct any inaccurate or incomplete personal information.</li>
              <li><span className="font-medium">Erasure:</span> The right to request deletion of your personal information under certain circumstances.</li>
              <li><span className="font-medium">Restriction:</span> The right to request that we restrict the processing of your personal information.</li>
              <li><span className="font-medium">Objection:</span> The right to object to our processing of your personal information.</li>
              <li><span className="font-medium">Data Portability:</span> The right to request the transfer of your personal information to another party.</li>
            </ul>
            <p className="mt-4">
              To exercise any of these rights, please contact us using the contact information provided below.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-800 mb-4">International Data Transfers</h2>
            <p className="mb-4">
              Your information, including personal data, may be transferred to—and maintained on—computers located 
              outside of your state, province, country, or other governmental jurisdiction where data protection 
              laws may differ.
            </p>
            <p>
              By submitting your personal information, you consent to this transfer.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-800 mb-4">Children's Privacy</h2>
            <p>
              Our Site is not intended for individuals under the age of 13. We do not knowingly collect personal 
              information from children under 13. If we become aware that a child under 13 has provided us with 
              personal information, we will take steps to delete such information.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-800 mb-4">Changes to This Privacy Policy</h2>
            <p>
              We reserve the right to modify this Privacy Policy at any time. We will notify you of any changes 
              by updating the "Last updated" date at the top of this Privacy Policy. Your continued use of the 
              Site after any changes constitutes your acceptance of the new Privacy Policy.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-800 mb-4">Contact Us</h2>
            <p className="mb-4">If you have any questions or concerns about this Privacy Policy, please contact us:</p>
            <div className="pl-6">
              <p>Email: <a href="mailto:info@kemahbay.com" className="text-blue-600 hover:text-blue-800">info@kemahbay.com</a></p>
              <p>Address: 6046 FM 2920 #146 Spring, Texas 77379-2542</p>
            </div>
          </section>

          <section className="text-sm text-gray-600 italic mt-8">
            <p>
              Disclaimer: This Privacy Policy is for informational purposes only and does not constitute legal advice. 
              It is recommended to consult with a qualified attorney to ensure compliance with all applicable laws 
              and regulations specific to your business and location.
            </p>
          </section>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default PrivacyPolicy;
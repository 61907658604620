import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet-async';


const Editing_ProofReading_Quote = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    bookTitle: '',
    genre: '',
    wordCount: '',
    selectedServices: [],
    manuscriptFormat: '',
    timeline: '',
    additionalServices: [],
    previousEditing: '',
    concerns: '',
    goals: ''
  });

  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState({ text: '', type: '' });

  const editingServices = [
    { name: 'Line Editing', rate: '$.005 per word', description: 'Refine your prose, enhance clarity, and improve flow' },
    { name: 'Developmental Editing', rate: '$.01 per word', description: 'Address plot, character, and structural issues' },
    { name: 'Copyediting', rate: '$.005 per word', description: 'Polish to catch any remaining errors' },
    { name: 'Proofreading', rate: '$.003 per word', description: 'Final detailed review for perfect presentation' }
  ];

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      const arrayField = name === 'selectedServices' ? 'selectedServices' : 'additionalServices';
      setFormData(prev => ({
        ...prev,
        [arrayField]: checked
          ? [...prev[arrayField], value]
          : prev[arrayField].filter(item => item !== value)
      }));
    } else {
      setFormData(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setIsLoading(true);
    setMessage({ text: '', type: '' });
  
    // Validate form data
    if (formData.selectedServices.length === 0) {
      setMessage({ 
        text: 'Please select at least one editing service', 
        type: 'error' 
      });
      setIsSubmitting(false);
      setIsLoading(false);
      return;
    }
  
    try {
      const response = await fetch('https://kemahbay.netlify.app/.netlify/functions/submit-editing', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      const data = await response.json();
  
      if (data.success) {
        setMessage({
          text: data.message || 'Quote request submitted successfully!',
          type: 'success'
        });
        
        // Reset form
        setFormData({
          name: '',
          email: '',
          bookTitle: '',
          genre: '',
          wordCount: '',
          selectedServices: [],
          manuscriptFormat: '',
          timeline: '',
          additionalServices: [],
          previousEditing: '',
          concerns: '',
          goals: ''
        });
  
        // Scroll to top to show success message
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } else {
        throw new Error(data.message || 'Submission failed');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setMessage({
        text: error.message || 'An error occurred while submitting the form. Please try again.',
        type: 'error'
      });
      
      // Scroll to top to show error message
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } finally {
      setIsSubmitting(false);
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-gray-900 text-white min-h-screen py-12">
      <Helmet>
        <title>Get an Editing Quote - Kemah Bay Marketing</title>
        <meta name="description" content="Request a customized quote for professional editing and proofreading services. Expert editing for all genres." />
        <meta property="og:title" content="Editing Quote - Kemah Bay Marketing" />
        <meta property="og:url" content="https://www.kemahbay.com/EditingProofReadingQuote" />
        <link rel="canonical" href="https://www.kemahbay.com/EditingProofReadingQuote" />
      </Helmet>
      <div className="container mx-auto px-4">
        <motion.h1
          className="text-4xl font-bold mb-8 text-center"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Submit Your Manuscript for Editing
        </motion.h1>
        
        {message.text && (
          <motion.div
            className={`mb-6 p-4 rounded ${message.type === 'success' ? 'bg-green-500' : 'bg-red-500'}`}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
          >
            {message.text}
          </motion.div>
        )}
        
        <motion.form
          onSubmit={handleSubmit}
          className="max-w-2xl mx-auto bg-gray-800 p-8 rounded-lg shadow-lg"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div>
              <label htmlFor="name" className="block mb-2">Your Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                className="w-full p-2 border rounded text-gray-300"
                required
              />
            </div>

            <div>
              <label htmlFor="email" className="block mb-2">Email Address</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className="w-full p-2 border rounded text-gray-300"
                required
              />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div>
              <label htmlFor="bookTitle" className="block mb-2">Book Title</label>
              <input
                type="text"
                id="bookTitle"
                name="bookTitle"
                value={formData.bookTitle}
                onChange={handleInputChange}
                className="w-full p-2 border rounded text-gray-300"
                required
              />
            </div>

            <div>
              <label htmlFor="genre" className="block mb-2">Genre</label>
              <input
                type="text"
                id="genre"
                name="genre"
                value={formData.genre}
                onChange={handleInputChange}
                className="w-full p-2 border rounded text-gray-300"
                required
              />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div>
              <label htmlFor="wordCount" className="block mb-2">Word Count</label>
              <input
                type="number"
                id="wordCount"
                name="wordCount"
                value={formData.wordCount}
                onChange={handleInputChange}
                className="w-full p-2 border rounded text-gray-300"
                required
                min="0"
              />
            </div>

            <div>
              <label htmlFor="manuscriptFormat" className="block mb-2">Manuscript Format</label>
              <select
                id="manuscriptFormat"
                name="manuscriptFormat"
                value={formData.manuscriptFormat}
                onChange={handleInputChange}
                className="w-full p-2 border rounded text-gray-300"
                required
              >
                <option value="">Select format</option>
                <option value="word2003">Microsoft Word 2003</option>
                <option value="word2007">Microsoft Word 2007+</option>
                <option value="wordx">Microsoft Word (.docx)</option>
              </select>
            </div>
          </div>

          <div className="mb-4">
            <label className="block mb-2">Select Editing Services Needed</label>
            <div className="grid grid-cols-1 gap-3">
              {editingServices.map((service) => (
                <div 
                  key={service.name}
                  className="bg-gray-700 p-4 rounded-lg"
                >
                  <label className="flex items-start">
                    <input
                      type="checkbox"
                      name="selectedServices"
                      value={service.name}
                      checked={formData.selectedServices.includes(service.name)}
                      onChange={handleInputChange}
                      className="mr-3 mt-1"
                    />
                    <div>
                      <span className="font-semibold block">{service.name}</span>
                      <span className="text-blue-400 text-sm block">{service.rate}</span>
                      <span className="text-gray-300 text-sm">{service.description}</span>
                    </div>
                  </label>
                </div>
              ))}
            </div>
          </div>

          <div className="mb-4">
            <label htmlFor="timeline" className="block mb-2">Preferred Timeline</label>
            <select
              id="timeline"
              name="timeline"
              value={formData.timeline}
              onChange={handleInputChange}
              className="w-full p-2 border rounded text-gray-300"
              required
            >
              <option value="">Select timeline</option>
              <option value="standard">Standard (3 business days per 20K words)</option>
              <option value="rush">Rush Service (Additional fee applies)</option>
              <option value="flexible">Flexible</option>
            </select>
          </div>

          <div className="mb-4">
            <label className="block mb-2">Additional Services Needed</label>
            <div className="grid grid-cols-2 gap-2">
              {[
                'Editorial Letter',
                'Track Changes',
                'Follow-up Consultation',
                'Phone Support',
                'Multiple Rounds',
                'Style Sheet Creation'
              ].map((service) => (
                <label key={service} className="flex items-center">
                  <input
                    type="checkbox"
                    name="additionalServices"
                    value={service}
                    checked={formData.additionalServices.includes(service)}
                    onChange={handleInputChange}
                    className="mr-2"
                  />
                  {service}
                </label>
              ))}
            </div>
          </div>

          <div className="mb-4">
            <label htmlFor="previousEditing" className="block mb-2">Previous Editing History</label>
            <textarea
              id="previousEditing"
              name="previousEditing"
              value={formData.previousEditing}
              onChange={handleInputChange}
              className="w-full p-2 border rounded text-gray-300"
              rows="3"
              placeholder="Has your manuscript been edited before? If yes, please describe."
            ></textarea>
          </div>

          <div className="mb-4">
            <label htmlFor="concerns" className="block mb-2">Specific Concerns</label>
            <textarea
              id="concerns"
              name="concerns"
              value={formData.concerns}
              onChange={handleInputChange}
              className="w-full p-2 border rounded text-gray-300"
              rows="3"
              placeholder="What specific areas of your manuscript would you like us to focus on?"
            ></textarea>
          </div>

          <div className="mb-6">
            <label htmlFor="goals" className="block mb-2">Editing Goals</label>
            <textarea
              id="goals"
              name="goals"
              value={formData.goals}
              onChange={handleInputChange}
              className="w-full p-2 border rounded text-gray-300"
              rows="3"
              placeholder="What are your goals for this editing process?"
            ></textarea>
          </div>

          <motion.button
            type="submit"
            className={`bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded ${
              isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            disabled={isSubmitting}
            whileHover={{ scale: isSubmitting ? 1 : 1.05 }}
            whileTap={{ scale: isSubmitting ? 1 : 0.95 }}
          >
            {isSubmitting ? 'Submitting...' : 'Submit Manuscript'}
          </motion.button>
        </motion.form>
      </div>
    </div>
  );
};

export default Editing_ProofReading_Quote;
import React from 'react';
import { Link } from 'react-router-dom';
import { Mail, MapPin } from 'lucide-react';

const Footer = () => {
  return (
    <footer className="bg-slate-950 text-white py-12">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="text-center">
            <h3 className="text-lg font-semibold mb-4">Site Map</h3>
            <ul className="space-y-2">
              <li><Link to="/" className="hover:text-cyan-400">HOME</Link></li>
              <li><Link to="/Contact" className="hover:text-cyan-400">Contact</Link></li>
              <li><Link to="/AudioBookDistribution" className="hover:text-cyan-400">Audio Book Distribution</Link></li>
              <li><Link to="/EditingProofReading" className="hover:text-cyan-400">Editing & Proofreading</Link></li>
              <li><Link to="/Marketing" className="hover:text-cyan-400">Marketing Services</Link></li>
              <li><Link to="/BookDoctor" className="hover:text-cyan-400">Book Doctor</Link></li>
            </ul>
          </div>
         
          <div className="text-center">
            <h3 className="text-lg font-semibold mb-4">Resources</h3>
            <ul className="space-y-2">
              <li><Link to="/faq" className="hover:text-cyan-400">FAQ</Link></li>
              <li><Link to="/TermsofService" className="hover:text-cyan-400">Terms and Conditions</Link></li>
              <li><Link to="/privacy" className="hover:text-cyan-400">Privacy</Link></li>
            </ul>
          </div>
         
          <div className="text-center">
            <h3 className="text-lg font-semibold mb-4">Contact Us</h3>
            <ul className="space-y-2">
              <li className="flex items-center justify-center">
                <Mail className="w-5 h-5 mr-2" />
                <a href="mailto:sales@kemahbay.com" className="hover:text-cyan-400">sales@kemahbay.com</a>
              </li>
              <li className="flex flex-col items-center">
                <div className="flex items-center justify-center mb-2">
                  <MapPin className="w-5 h-5 mr-2" />
                  <p>6046 FM 2920 #146</p>
                </div>
                <div>
                  <p>Spring, Texas 77379-2542</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
       
        <div className="mt-8 pt-8 border-t border-gray-800 text-center">
          <p>&copy; {new Date().getFullYear()} Kemah Bay Marketing, LLC. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
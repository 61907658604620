import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Download, Upload, CheckCircle, XCircle, FileAudio, FileText, Image } from 'lucide-react';
import Footer from "./Footer"
import { Helmet } from 'react-helmet-async';

const Input = ({ label, type, name, value, onChange, required }) => (
  <div className="mb-4">
    <label className="block text-sm font-medium mb-1 text-gray-300" htmlFor={name}>{label}</label>
    <input
      type={type}
      id={name}
      name={name}
      value={value}
      onChange={onChange}
      required={required}
      className="w-full p-2 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-white"
    />
  </div>
);

const Button = ({ icon: Icon, text, type = "button", onClick }) => (
  <motion.button
    type={type}
    onClick={onClick}
    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded flex items-center justify-center"
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
  >
    <Icon className="mr-2" size={20} />
    <span>{text}</span>
  </motion.button>
);

const FileUpload = ({ label, accept, icon: Icon, onChange, file }) => (
  <div className="mb-6">
    <label className="block text-sm font-medium mb-1 text-gray-300">{label}</label>
    <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-600 border-dashed rounded-md bg-gray-800">
      <div className="space-y-1 text-center">
        <Icon className="mx-auto h-12 w-12 text-gray-400" />
        <div className="flex text-sm text-gray-400">
          <label htmlFor={`file-upload-${label}`} className="relative cursor-pointer bg-gray-800 rounded-md font-medium text-blue-400 hover:text-blue-300 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500">
            <span>Upload a file</span>
            <input id={`file-upload-${label}`} name={`file-upload-${label}`} type="file" className="sr-only" onChange={onChange} accept={accept} />
          </label>
          <p className="pl-1">or drag and drop</p>
        </div>
        <p className="text-xs text-gray-500">{accept.split(',').join(', ')}</p>
      </div>
    </div>
    {file && (
      <p className="mt-2 text-sm text-gray-400">
        Selected file: {file.name} ({(file.size / (1024 * 1024)).toFixed(2)} MB)
      </p>
    )}
  </div>
);

const AudioBookSubmissionForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    authorName: '',
    email: '',
    bookTitle: '',
    genre: '',
    audioLength: '',
    acxUsername: ''
  });

  const [files, setFiles] = useState({
    signedAgreement: null,
    audioClips: [],
    coverImage: null
  });

  const [uploadStatus, setUploadStatus] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleFileUpload = (type, e) => {
    const file = e.target.files[0];
    if (file) {
      if (type === 'audioClips') {
        setFiles(prev => ({
          ...prev,
          audioClips: [...prev.audioClips, file]
        }));
        setUploadStatus(prev => ({
          ...prev,
          [type]: { success: true, message: `Audio clip "${file.name}" added successfully` }
        }));
      } else {
        setFiles(prev => ({
          ...prev,
          [type]: file
        }));
        setUploadStatus(prev => ({
          ...prev,
          [type]: { success: true, message: `${type} uploaded successfully` }
        }));
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
  
    try {
      if (!files.signedAgreement || !files.coverImage) {
        setUploadStatus(prev => ({
          ...prev,
          form: { success: false, message: 'Please upload the required files (Agreement and Cover Image) before submitting.' }
        }));
        return;
      }
  
      const formDataToSend = new FormData();
  
      // Append form fields
      Object.keys(formData).forEach(key => {
        formDataToSend.append(key, formData[key]);
      });
  
      // Append files
      formDataToSend.append('signedAgreement', files.signedAgreement);
      formDataToSend.append('coverImage', files.coverImage);
  
      const response = await fetch('https://kemahbay.netlify.app/.netlify/functions/submit-audiobook', {
        method: 'POST',
        body: formDataToSend,
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const result = await response.json();
      console.log('Submission successful:', result);
  
      if (result.success) {
        setUploadStatus(prev => ({
          ...prev,
          form: { success: true, message: result.message }
        }));
  
        // Reset form and files
        setFormData({
          authorName: '',
          email: '',
          bookTitle: '',
          genre: '',
          audioLength: '',
          acxUsername: ''
        });
        setFiles({
          signedAgreement: null,
          audioClips: [],
          coverImage: null
        });
      } else {
        throw new Error(result.message || 'Submission failed');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setUploadStatus(prev => ({
        ...prev,
        form: {
          success: false,
          message: error.message || 'An error occurred during submission. Please try again.'
        }
      }));
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDownloadAgreement = () => {
    // Trigger download of the agreement PDF
    // This is a placeholder URL - replace with your actual agreement PDF
    const agreementUrl = "/resources/agreement.pdf"
    window.open(agreementUrl, '_blank');
  };

  return (
    <div className="bg-gray-900 text-white min-h-screen">
      <Helmet>
        <title>Submit Your Audiobook - Kemah Bay Marketing</title>
        <meta name="description" content="Submit your book for audiobook production. Start your journey to audio publishing today." />
        <meta property="og:title" content="Audiobook Submission - Kemah Bay Marketing" />
        <meta property="og:url" content="https://www.kemahbay.com/AudioBookSubmissionForm" />
        <link rel="canonical" href="https://www.kemahbay.com/AudioBookSubmissionForm" />
      </Helmet>

      <main className="container mx-auto px-4 py-12">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="max-w-3xl mx-auto bg-gray-800 shadow-lg rounded-lg overflow-hidden"
        >
          <div className="px-4 py-5 sm:p-6">
            <h1 className="text-3xl font-bold text-center mb-8">Submit Your Audiobook</h1>
            
            <div className="mb-8">
              <h2 className="text-xl font-semibold mb-4">Before You Begin</h2>
              <p className="mb-4 text-gray-300">Please download our distribution agreement, sign it, and upload the signed copy below.</p>
              <Button
                icon={Download}
                text="Download Agreement"
                onClick={handleDownloadAgreement}
              />
            </div>

            <form onSubmit={handleSubmit}>
              <Input
                label="Author Name"
                type="text"
                name="authorName"
                value={formData.authorName}
                onChange={handleInputChange}
                required
              />
              <Input
                label="Email"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
              <Input
                label="Book Title"
                type="text"
                name="bookTitle"
                value={formData.bookTitle}
                onChange={handleInputChange}
                required
              />
              <Input
                label="Genre"
                type="text"
                name="genre"
                value={formData.genre}
                onChange={handleInputChange}
                required
              />
              <Input
                label="Total Audio Length (HH:MM:SS)"
                type="text"
                name="audioLength"
                value={formData.audioLength}
                onChange={handleInputChange}
                required
              />
              <Input
                label="ACX Username (if applicable)"
                type="text"
                name="acxUsername"
                value={formData.acxUsername}
                onChange={handleInputChange}
              />

              <FileUpload
                label="Upload Signed Agreement"
                accept=".pdf"
                icon={FileText}
                onChange={(e) => handleFileUpload('signedAgreement', e)}
                file={files.signedAgreement}
              />

              <FileUpload
                label="Upload Audio Clips"
                accept="audio/mpeg,audio/mp4,audio/x-m4a,audio/wav"
                icon={FileAudio}
                onChange={(e) => handleFileUpload('audioClips', e)}
                file={files.audioClips[files.audioClips.length - 1]}
              />
              {files.audioClips.length > 0 && (
                <div className="mb-4">
                  <p className="font-medium text-gray-300">Uploaded Audio Clips:</p>
                  <ul className="list-disc list-inside text-gray-400">
                    {files.audioClips.map((clip, index) => (
                      <li key={index}>{clip.name}</li>
                    ))}
                  </ul>
                </div>
              )}

              <FileUpload
                label="Upload Cover Image"
                accept="image/jpeg,image/png,image/gif"
                icon={Image}
                onChange={(e) => handleFileUpload('coverImage', e)}
                file={files.coverImage}
              />

              {uploadStatus.form && (
                <p className={`mt-2 text-sm flex items-center ${uploadStatus.form.success ? 'text-green-400' : 'text-red-400'}`}>
                  {uploadStatus.form.success ? <CheckCircle className="mr-2" size={16} /> : <XCircle className="mr-2" size={16} />}
                  {uploadStatus.form.message}
                </p>
              )}

              <div className="mt-8">
              <Button
                icon={Upload}
                text={isSubmitting ? "Submitting..." : "Submit Audiobook Information"}
                type="submit"
                disabled={isSubmitting}
                onClick={null}
                className={`${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
              />
              </div>
            </form>
          </div>
        </motion.div>
      </main>
      <Footer/>
    </div>
  );
};

export default AudioBookSubmissionForm;
import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import './index.css';
import App from './App';

const helmetContext = {};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HelmetProvider context={helmetContext}>
      <App />
    </HelmetProvider>
  </React.StrictMode>
);

// Disable console in production
if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
  console.debug = () => {};
  console.info = () => {};
  // Keep error and warn for debugging
}

// Prevent certain browser behaviors
if (typeof window !== 'undefined') {
  // Disable right-click in production
  if (process.env.NODE_ENV === 'production') {
    window.addEventListener('contextmenu', (e) => e.preventDefault());
  }
  
  // Disable dev tools shortcut
  window.addEventListener('keydown', (e) => {
    if (
      (e.ctrlKey && e.shiftKey && (e.key === 'I' || e.key === 'J' || e.key === 'C')) ||
      (e.key === 'F12')
    ) {
      e.preventDefault();
    }
  });
}


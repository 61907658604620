import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import NavBar from './Navbar';
import Home from './Home';
import Contact from './Contact';
import AudioBookDistribution from './AudioBookDistribution';
import Marketing from './Marketing';
import EditingProofreading from './EditingProofreading';
import BookDoctor from './BookDoctor';
import FAQ from './FAQ';
import AudioBookSubmissionForm from './AudioBookSubmissionForm';
import MarketingQuotePage from './MarketingQuotePage';
import Privacy from './Privacy'
import TermsofService from './Terms of Service'
import EditingProofReadingQuote from './EditingProofReadingQuote'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import NotFound from './NotFound';

// ScrollToTop component
function ScrollToTop() {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <HelmetProvider>
      <Router>
        <Helmet>
          <title>Kemah Bay Marketing - Independent Book Publishing Services</title>
          <meta name="description" content="Professional book publishing services for independent authors. Expert editing, marketing, and distribution services based in Houston, TX." />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Kemah Bay Marketing - Book Publishing Services" />
          <meta property="og:description" content="Professional book publishing services for independent authors. Expert editing, marketing, and distribution services." />
          <meta property="og:url" content="https://www.kemahbay.com" />
          <link rel="canonical" href="https://www.kemahbay.com" />
          <meta name="robots" content="index, follow" />
        </Helmet>
        <ScrollToTop />
        <div className="App">
          <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/BookDoctor" element={<BookDoctor />} />
          <Route path="/AudioBookDistribution" element={<AudioBookDistribution />} />
          <Route path="/EditingProofreading" element={<EditingProofreading />} />
          <Route path="/Marketing" element={<Marketing />} />
          <Route path="/FAQ" element={<FAQ />} />
          <Route path="/AudioBookSubmissionForm" element={<AudioBookSubmissionForm />} />
          <Route path="/MarketingQuotePage" element={<MarketingQuotePage />} />
          <Route path="/Privacy" element={<Privacy />} />
          <Route path="/TermsofService" element={<TermsofService />} />
          <Route path="/EditingProofReadingQuote" element={<EditingProofReadingQuote />} />
          <Route path="*" element={<NotFound />} />
          {/* Add more routes as needed */}
        </Routes>
        </div>
      </Router>
    </HelmetProvider>
  );
}

export default App;
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Mail, MapPin, Clock } from 'lucide-react';
import Footer from './Footer';
import { Helmet } from 'react-helmet-async';

const ContactInfoItem = ({ icon: Icon, title, children }) => (
  <div className="flex items-start mb-6">
    <Icon className="w-6 h-6 text-blue-500 mr-4 mt-1" />
    <div>
      <p className="font-medium text-blue-400">{title}</p>
      {children}
    </div>
  </div>
);


const ContactPage = () => {
  const [formData, setFormData] = useState({
    manuscriptReady: '',
    publishedBefore: '',
    bookType: '',
    name: '',
    email: '',
    services: []
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setFormData(prev => ({
        ...prev,
        services: checked
          ? [...prev.services, value]
          : prev.services.filter(service => service !== value)
      }));
    } else {
      setFormData(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('http://localhost:5000/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      alert(data.message); // You might want to replace this with a more user-friendly notification
      console.log('Form submitted:', formData);
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('An error occurred while submitting the form. Please try again.');
    }
  };

  return (
    <div className="bg-gray-900 text-white min-h-screen">
      <Helmet>
        <title>Contact Us - Kemah Bay Marketing</title>
        <meta name="description" content="Get in touch with Kemah Bay Marketing for professional book publishing services. We're here to help with your publishing journey." />
        <meta property="og:title" content="Contact Kemah Bay Marketing" />
        <meta property="og:url" content="https://www.kemahbay.com/Contact" />
        <link rel="canonical" href="https://www.kemahbay.com/Contact" />
      </Helmet>

      <main>
        <section className="container mx-auto px-4 py-20">
          <motion.h1
            className="text-5xl font-bold mb-8 text-center"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            Contact Us
          </motion.h1>

          <div className="grid md:grid-cols-2 gap-12">
            <motion.div
              className="bg-gray-800 rounded-lg shadow-lg p-8"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
            >
              <h2 className="text-2xl font-semibold mb-6 text-blue-400">Get in Touch</h2>
              <ContactInfoItem icon={MapPin} title="Address">
                <p>6046 FM 2920</p>
                <p>#146</p>
                <p>Spring, Texas 77379-2542</p>
              </ContactInfoItem>
              <ContactInfoItem icon={Mail} title="For other questions or direct queries, please email">
                <a href="mailto:sales@kemahbay.com" className="text-blue-500 hover:underline">sales@kemahbay.com</a>
              </ContactInfoItem>
              <ContactInfoItem icon={Clock} title="Business Hours">
                <p>Monday - Friday: 9:00 AM - 5:00 PM CST</p>
                <p>Saturday - Sunday: Closed</p>
              </ContactInfoItem>
            </motion.div>

            <motion.div
              className=" bg-gray-800 rounded-lg shadow-lg p-8"
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
            >
              <form onSubmit={handleSubmit} className="text-white space-y-6">
                <div>
                  <label className="block mb-2 text-left">Do you have a manuscript ready?</label>
                  <select 
                    name="manuscriptReady" 
                    onChange={handleInputChange} 
                    className="w-full p-3 border rounded bg-gray-700 text-white focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                  >
                    <option value="">Select...</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>

                <div>
                  <label className="block mb-2 text-left">Have you published before?</label>
                  <select 
                    name="publishedBefore" 
                    onChange={handleInputChange} 
                    className="w-full p-3 border rounded bg-gray-700 text-white focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                  >
                    <option value="">Select...</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>

                <div>
                  <label htmlFor="bookType" className="block mb-2 text-left">What genre of book do you plan on publishing?</label>
                  <input
                    type="text"
                    id="bookType"
                    name="bookType"
                    onChange={handleInputChange}
                    className="w-full p-3 border rounded bg-gray-700 text-white focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                    placeholder="Enter your book genre"
                  />
                </div>

                <div>
                  <label htmlFor="name" className="block mb-2 text-left">What is your name?</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    onChange={handleInputChange}
                    className="w-full p-3 border rounded bg-gray-700 text-white focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                    placeholder="Please enter your first and last name here"
                  />
                </div>

                <div>
                  <label htmlFor="email" className="block mb-2 text-left">Email Address</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    onChange={handleInputChange}
                    className="w-full p-3 border rounded bg-gray-700 text-white focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                    placeholder="Please enter your email address here"
                    required
                  />
                </div>

                <div>
                  <label className="block mb-2 text-left">What Services Are You Looking For?</label>
                  <div className="grid grid-cols-2 gap-4">
                    {['Self Publishing', 'Editing', 'Cover Consultation', 'Printing', 'Interior Formatting', 'International Kindle Publishing'].map((service) => (
                      <label key={service} className="flex items-center space-x-2 text-left">
                        <input
                          type="checkbox"
                          name="services"
                          value={service}
                          onChange={handleInputChange}
                          className="rounded border-gray-400 text-blue-500 focus:ring-blue-500"
                        />
                        <span>{service}</span>
                      </label>
                    ))}
                  </div>
                </div>

                <button type="submit" className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
                  Submit
                </button>
              </form>
            </motion.div>
          </div>
        </section>
      </main>

      <Footer />
    </div>
  );
};

export default ContactPage;
import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown, Headphones, Play, ChevronRight, Upload } from 'lucide-react';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const Button = ({ icon: Icon, text, downloadLink, onClick }) => (
  <motion.a
    href={downloadLink}
    download={!!downloadLink}
    onClick={onClick}
    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-6 rounded-lg flex items-center justify-center gap-2 shadow-lg shadow-blue-500/30 transition-shadow"
    whileHover={{ scale: 1.02, boxShadow: "0 8px 30px rgba(59, 130, 246, 0.5)" }}
    whileTap={{ scale: 0.98 }}
  >
    <Icon size={22} />
    <span className="text-lg">{text}</span>
  </motion.a>
);

// Improved FAQItem
const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <motion.div 
      className="mb-3"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      <button
        className={`w-full flex items-center justify-between p-5 rounded-lg text-left transition-all duration-200
          ${isOpen ? 'bg-gray-700/80' : 'bg-gray-800/40 hover:bg-gray-700/60'}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <h3 className="font-medium text-gray-200 text-lg">{question}</h3>
        <motion.div
          initial={false}
          animate={{ rotate: isOpen ? 180 : 0 }}
          transition={{ duration: 0.2 }}
        >
          <ChevronDown className="w-5 h-5 text-blue-400" />
        </motion.div>
      </button>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ 
              height: "auto",
              opacity: 1,
              transition: {
                height: { duration: 0.2 },
                opacity: { duration: 0.2 }
              }
            }}
            exit={{ 
              height: 0,
              opacity: 0,
              transition: {
                height: { duration: 0.2 },
                opacity: { duration: 0.1 }
              }
            }}
            className="overflow-hidden"
          >
            <div className="px-5 py-4 text-gray-300 text-lg">
              <p>{answer}</p>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};


const AudioBookDistributionPage = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-gray-900 text-white min-h-screen">
      <Helmet>
        <title>Audiobook Distribution Services - Kemah Bay Marketing</title>
        <meta name="description" content="Professional audiobook production and distribution services. Get your book into audio format and distributed across major platforms." />
        <meta property="og:title" content="Audiobook Distribution Services - Kemah Bay Marketing" />
        <meta property="og:url" content="https://www.kemahbay.com/AudioBookDistribution" />
        <link rel="canonical" href="https://www.kemahbay.com/AudioBookDistribution" />
      </Helmet>

      <main>
      <section className="relative h-screen flex items-center justify-center">
        <div className="absolute inset-0 bg-gradient-to-b from-gray-900/50 to-gray-900/90 z-10" />
        <video
          autoPlay
          loop
          muted
          playsInline
          className="absolute inset-0 w-full h-full object-cover pointer-events-none"
        >
          <source src={process.env.PUBLIC_URL + '/resources/155064-809859862.mp4'} type="video/mp4" />
        </video>
        <div className="relative z-20 text-center max-w-4xl px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            className="space-y-8"
          >
            <h1 className="text-6xl font-bold mb-6 bg-clip-text">
              Audio Book Distribution
            </h1>
            <p className="text-2xl text-gray-200 mb-8">
              Amplify your reach. Live outside of the US, UK, Irland, or Canada? We can help get your audiobook on Audible!
            </p>
            <Button icon={Headphones} text="Get Started Today" onClick={() => navigate('/AudioBookSubmissionForm')} />
          </motion.div>
        </div>
      </section>

        <section className="container mx-auto px-4 py-20">
          <motion.h2
            className="text-3xl font-bold mb-10 text-center"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
          >
            Why Choose Kemah Bay?
          </motion.h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
             <motion.img
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: true }}
              src="/resources/earth-11595.jpg" 
              >
             </motion.img>
            
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: true }}
            >
              <h3 className="text-2xl font-semibold mb-4">Global Reach</h3>
              <p className="mb-4 text-lg">Distribute your audiobooks worldwide through Audible, Amazon, and iTunes. Reach millions of potential listeners and expand your author brand globally.</p>
              <ul className="list-disc list-inside text-lg">
                <li>Access to major audiobook platforms</li>
                <li>Expanding your audience across borders</li>
                <li>Increased visibility for your work</li>
              </ul>
            </motion.div>
          </div>
        </section>

        <section className="container mx-auto px-4 py-20">
          <motion.h2
            className="text-3xl font-bold mb-10 text-center"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
          >
            How It Works
          </motion.h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 text-lg">
            {[
              { icon: Headphones, title: "Record Your Book", description: "Create high-quality audio recordings of your book." },
              { icon: Upload, title: "Submit Your Files", description: "Send us your audio files and cover image." },
              { icon: Play, title: "Start Earning", description: "We'll handle distribution, and you start earning royalties." },
            ].map((step, index) => (
              <motion.div
                key={index}
                className="flex flex-col items-center text-center"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: index * 0.2 }}
                viewport={{ once: true }}
              >
                <div className="bg-blue-500 rounded-full p-4 mb-4">
                  <step.icon size={40} />
                </div>
                <h3 className="text-xl font-semibold mb-2">{step.title}</h3>
                <p>{step.description}</p>
                {index < 2 && <ChevronRight className="mt-4 text-blue-500" size={24} />}
              </motion.div>
            ))}
          </div>
        </section>

        <section className="bg-gray-800/50 backdrop-blur-sm py-20">
          <div className="container mx-auto px-4 ">
            <motion.h2
              className="text-4xl font-bold mb-12 text-center bg-clip-text text-transparent bg-gradient-to-r from-white to-blue-400"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: true }}
            >
              Our Process
            </motion.h2>
            <div className="relative ">
              <div className="absolute inset-0 flex items-center justify-center">
                <div className="w-1 h-full bg-blue-500"></div>
              </div>
              {[
                { title: "Submit Your Audio", description: "Send us your high-quality audio files and cover art." },
                { title: "We Handle Distribution", description: "Our team takes care of uploading and managing your audiobook across platforms." },
                { title: "Track Your Success", description: "Monitor your sales and earnings through our user-friendly dashboard." },
                { title: "Receive Payments", description: "Get 90% of your earnings transferred via PayPal monthly." },
              ].map((step, index) => (
                <motion.div
                  key={index}
                  className="relative z-10 flex items-center mb-8 text-lg"
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8, delay: index * 0.2 }}
                  viewport={{ once: true }}
                >
                  <div className="bg-blue-500 rounded-full p-2 mr-4">
                    <div className="bg-white rounded-full p-2">
                      <span className="text-blue-500 font-bold">{index + 1}</span>
                    </div>
                  </div>
                  <div className="bg-gray-700 rounded-lg p-4 flex-grow">
                    <h3 className="text-xl font-semibold mb-2">{step.title}</h3>
                    <p>{step.description}</p>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        

        {/* FAQ Section */}
        <section className="py-20 bg-gray-900/90 backdrop-blur-sm">
          <div className="container mx-auto px-4">
            <motion.h2
              className="text-4xl font-bold mb-12 text-center bg-clip-text"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: true }}
            >
              Frequently Asked Questions
            </motion.h2>
            <div className="max-w-3xl mx-auto space-y-3">
              <FAQItem 
                question="What if ACX makes its services available in my country of residence?"
                answer= "Your existing books with Kemah Bay must remain in our account for five years after being published. This is an ACX requirement. After that time, we can delist the title and then you can upload the book to your own ACX account."
              />
              <FAQItem 
                question="Is there any limit to the number of titles or author names with Kemah Bay's service?"
                answer="No, there is no limit. You can publish as many titles and use as many author names as you wish through our service."
              />
              <FAQItem 
                question="What if I don't like KBM's service, or if there are problems?"
                answer="We do our best to provide the highest standards of customer service. We also work closely with ACX to ensure a smooth partnership that benefits all parties. Between the author, ACX, and Kemah Bay, we have yet to encounter an issue that can't be resolved to everyone's satisfaction."
              />
              <FAQItem 
                question="Will Kemah Bay help with narrator selection or audio book marketing as part of this service?"
                answer="No. You must deliver acceptable audio files to us. Our offering is strictly a distribution for pay agreement. However, we can provide guidance on best practices for narrator selection and marketing if needed."
              />
              <FAQItem 
                question="Does Kemah Bay withhold taxes from the royalty payments?"
                answer="No. Nor does ACX withhold any US taxes. One of the reasons why we work exclusively through PayPal is that they deal with much of the paperwork regarding US tax requirements and filings. Each individual author is responsible for filing taxes in the appropriate country of residence."
              />
              <FAQItem 
                question="How long does it take to publish an audio book?"
                answer="The process typically takes 4-8 weeks. Once you deliver the audio files to us, we upload them to ACX. They perform a quality control check of the book and audio levels, which takes an average of 30 days. After ACX has approved the title, it takes another 3-5 days before the audio book is live on Amazon, Audible, and other platforms."
              />
              <FAQItem 
                question="I am a non-USA narrator and want to offer my services via ACX. Can I sign up?"
                answer="Unfortunately, no. Our agreement with ACX is only for authors/publishers at this time. We'll update our services if this changes in the future."
              />
              <FAQItem 
                question="What if ACX doesn't reimburse me correctly for royalties owed?"
                answer="We will act as the author's agent if there are royalty disputes or other support issues with ACX. While it has never occurred to date, if a payment is lost in the mail, or goes missing, we will work with you to resolve the situation promptly."
              />
            </div>
          </div>
        </section>

        <section className="bg-gray-700 container mx-auto rounded-lg px-4 py-12 mb-12">
          <motion.div
            className="text-center max-w-2xl mx-auto"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
          >
            <h2 className="text-2xl font-bold mb-4">Ready to Amplify Your Author Journey?</h2>
            <p className="text-lg mb-6">Let us help you bring your books to life through audio. Are you ready to reach a whole new audience?</p>
            <Button 
              icon={Headphones} 
              text="Get Started" 
              onClick={() => navigate('/AudioBookSubmissionForm')}
              className="py-2 px-4 text-sm" // Smaller button styling
            />
          </motion.div>
          </section>

      </main>
      <Footer/>
    </div>
  );
};

export default AudioBookDistributionPage;
import React from 'react';
import Footer from './Footer'
import { Helmet } from 'react-helmet-async';

const TermsOfService = () => {
  return (
    <div className="min-h-screen bg-gray-900 py-12 px-4 sm:px-6 lg:px-8">
      <Helmet>
        <title>Terms of Service - Kemah Bay Marketing</title>
        <meta name="description" content="Our terms of service outline the conditions for using our publishing and marketing services." />
        <meta property="og:title" content="Terms of Service - Kemah Bay Marketing" />
        <meta property="og:url" content="https://www.kemahbay.com/TermsofService" />
        <link rel="canonical" href="https://www.kemahbay.com/TermsofService" />
      </Helmet>
      <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-md p-8">
        <h1 className="text-3xl font-bold text-center text-gray-900 mb-4">Terms of Service</h1>
        <p className="text-center text-gray-600 mb-8">Last updated: October 31, 2024</p>
        
        <div className="space-y-6 text-gray-700">
          <section>
            <h2 className="text-xl font-semibold text-gray-800 mb-4">1. Acceptance of Terms</h2>
            <p>
              By accessing or using the website https://kemahbay.com/ (the "Site") operated by Kemah Bay Marketing LLC
              ("Company," "we," "us," or "our"), you ("User" or "you") agree to be bound by these Terms of Service 
              ("Terms") and our Privacy Policy. If you do not agree to all the terms and conditions, you must not 
              access or use the Site.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-800 mb-4">2. Eligibility</h2>
            <p>
              By using the Site, you represent that you are at least 18 years old and have the legal capacity to 
              enter into these Terms. If you are using the Site on behalf of an organization, you represent and 
              warrant that you have the authority to bind that organization to these Terms.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-800 mb-4">3. Changes to Terms</h2>
            <p>
              We reserve the right to modify or replace these Terms at any time at our sole discretion. Any changes 
              will be effective immediately upon posting the revised Terms on the Site. Your continued use of the 
              Site following the posting of any changes constitutes acceptance of those changes.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-800 mb-4">4. Use of the Site</h2>
            <div className="space-y-4">
              <div>
                <h3 className="font-medium text-gray-800 mb-2">4.1 Permitted Use</h3>
                <p>
                  You are granted a non-exclusive, non-transferable, revocable license to access and use the Site 
                  strictly in accordance with these Terms.
                </p>
              </div>
              <div>
                <h3 className="font-medium text-gray-800 mb-2">4.2 Prohibited Activities</h3>
                <p className="mb-2">You agree not to:</p>
                <ul className="list-disc pl-6 space-y-2">
                  <li>Violate any applicable laws or regulations.</li>
                  <li>Use the Site for any unlawful or unauthorized purpose.</li>
                  <li>Disrupt or interfere with the security or performance of the Site.</li>
                  <li>Upload or transmit viruses or other harmful code.</li>
                  <li>Collect or store personal data about other users without their consent.</li>
                  <li>Engage in any form of automated data collection, such as scraping or harvesting.</li>
                </ul>
              </div>
            </div>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-800 mb-4">5. Intellectual Property Rights</h2>
            <div className="space-y-4">
              <div>
                <h3 className="font-medium text-gray-800 mb-2">5.1 Ownership</h3>
                <p>
                  All content, features, and functionality on the Site—including but not limited to text, graphics, 
                  logos, images, and software—are the exclusive property of Kemah Bay Marketing LLC and are protected by 
                  copyright, trademark, and other intellectual property laws.
                </p>
              </div>
              <div>
                <h3 className="font-medium text-gray-800 mb-2">5.2 Limited License</h3>
                <p>
                  You may access and use the Site for your personal, non-commercial use. Any other use, including 
                  reproduction, distribution, or modification, without our prior written consent is strictly prohibited.
                </p>
              </div>
            </div>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-800 mb-4">6. User Contributions</h2>
            <div className="space-y-4">
              <div>
                <h3 className="font-medium text-gray-800 mb-2">6.1 Content Submission</h3>
                <p>
                  By submitting any content to the Site, including book details or other materials ("User Content"), 
                  you grant us a worldwide, perpetual, irrevocable, royalty-free, sublicensable license to use, 
                  reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display 
                  such User Content.
                </p>
              </div>
              <div>
                <h3 className="font-medium text-gray-800 mb-2">6.2 Representations and Warranties</h3>
                <p className="mb-2">You represent and warrant that:</p>
                <ul className="list-disc pl-6 space-y-2">
                  <li>You own or have the necessary rights to submit the User Content.</li>
                  <li>The User Content does not infringe on any third-party rights.</li>
                  <li>The User Content complies with all applicable laws and regulations.</li>
                </ul>
              </div>
            </div>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-800 mb-4">7. Privacy Policy</h2>
            <p>
              Your use of the Site is also governed by our Privacy Policy, which is incorporated into these Terms 
              by reference. By using the Site, you consent to the collection and use of your information as outlined 
              in the Privacy Policy.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-800 mb-4">8. Third-Party Links and Services</h2>
            <p>
              The Site may contain links to third-party websites or services that are not owned or controlled by us. 
              We have no control over and assume no responsibility for the content, privacy policies, or practices of 
              any third-party sites. You access any third-party sites at your own risk.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-800 mb-4">9. Disclaimer of Warranties</h2>
            <div className="space-y-4">
              <div>
                <h3 className="font-medium text-gray-800 mb-2">9.1 "AS IS" and "AS AVAILABLE"</h3>
                <p>
                  The Site is provided on an "AS IS" and "AS AVAILABLE" basis without warranties of any kind, either 
                  express or implied.
                </p>
              </div>
              <div>
                <h3 className="font-medium text-gray-800 mb-2">9.2 No Warranties</h3>
                <p className="mb-2">We disclaim all warranties, express or implied, including but not limited to:</p>
                <ul className="list-disc pl-6 space-y-2">
                  <li>Fitness for a particular purpose.</li>
                  <li>Non-infringement.</li>
                  <li>Accuracy, reliability, or completeness of any content on the Site.</li>
                </ul>
              </div>
            </div>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-800 mb-4">10. Limitation of Liability</h2>
            <p>
              To the fullest extent permitted by law, in no event shall Kemah Bay Marketing LLC, its affiliates, 
              directors, employees, or agents be liable for any indirect, incidental, special, consequential, or 
              punitive damages arising out of or related to your use or inability to use the Site.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-800 mb-4">11. Indemnification</h2>
            <p>
              You agree to indemnify, defend, and hold harmless Kemah Bay Marketing LLC and its affiliates from any 
              claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable 
              attorneys' fees) arising out of or relating to your violation of these Terms or your use of the Site.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-800 mb-4">12. Termination</h2>
            <p>
              We reserve the right to terminate or suspend your access to the Site, without prior notice or liability, 
              for any reason whatsoever, including but not limited to a breach of these Terms.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-800 mb-4">13. Governing Law and Jurisdiction</h2>
            <p>
              These Terms are governed by and construed in accordance with the laws of the State of [Your State], 
              without regard to its conflict of law provisions. You agree to submit to the personal jurisdiction of 
              the state and federal courts located in [Your County], [Your State], for the resolution of any disputes.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-800 mb-4">14. Dispute Resolution</h2>
            <div className="space-y-4">
              <div>
                <h3 className="font-medium text-gray-800 mb-2">14.1 Arbitration</h3>
                <p>
                  Any dispute arising out of or relating to these Terms or the Site shall be resolved by binding 
                  arbitration administered by the American Arbitration Association under its Commercial Arbitration Rules.
                </p>
              </div>
              <div>
                <h3 className="font-medium text-gray-800 mb-2">14.2 Class Action Waiver</h3>
                <p>
                  You agree that any arbitration shall be conducted in your individual capacity only and not as a 
                  class action or other representative action.
                </p>
              </div>
            </div>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-800 mb-4">15. Notices</h2>
            <p className="mb-4">All notices required or permitted under these Terms shall be in writing and delivered to:</p>
            <div className="pl-6">
              <p>Email: <a href="mailto:info@kemahbay.com" className="text-blue-600 hover:text-blue-800">info@kemahbay.com</a></p>
              <p>Address: Kemah Bay Marketing LLC, [6046 FM 2920 #146 Spring, Texas 77379-2542]</p>
            </div>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-800 mb-4">16. Severability</h2>
            <p>
              If any provision of these Terms is held to be invalid or unenforceable, such provision shall be struck 
              and the remaining provisions shall be enforced to the fullest extent under law.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-800 mb-4">17. Waiver</h2>
            <p>
              No waiver of any term or condition set forth in these Terms shall be deemed a further or continuing 
              waiver of such term or condition or any other term or condition.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-800 mb-4">18. Entire Agreement</h2>
            <p>
              These Terms and our Privacy Policy constitute the sole and entire agreement between you and Kemah Bay 
              Marketing LLC regarding the Site and supersede all prior and contemporaneous understandings.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-800 mb-4">19. Assignment</h2>
            <p>
              You may not assign your rights or obligations under these Terms without our prior written consent. 
              We may assign our rights and obligations to a third party at any time without notice.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-800 mb-4">20. Force Majeure</h2>
            <p>
              We shall not be liable for any failure or delay in performance due to causes beyond our reasonable 
              control, including but not limited to acts of God, war, terrorism, labor disputes, or failure of 
              public utilities.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-800 mb-4">21. Electronic Communications</h2>
            <p>
              By using the Site, you consent to receive electronic communications from us. You agree that all 
              agreements, notices, disclosures, and other communications we provide electronically satisfy any 
              legal requirement that such communications be in writing.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-800 mb-4">22. Contact Information</h2>
            <p className="mb-4">If you have any questions about these Terms, please contact us:</p>
            <div className="pl-6">
              <p>Email: <a href="mailto:info@kemahbay.com" className="text-blue-600 hover:text-blue-800">info@kemahbay.com</a></p>
              <p>Address: Kemah Bay Marketing LLC, [6046 FM 2920 #146 Spring, Texas 77379-2542]</p>
            </div>
          </section>

          <section className="text-sm text-gray-600 italic mt-8">
            <p>
              Disclaimer: This Terms of Service document is provided for informational purposes only and does not 
              constitute legal advice. Laws and regulations vary by jurisdiction and may change over time. You should 
              consult with a qualified attorney to ensure that your Terms of Service are compliant with all applicable 
              laws and are tailored to your specific business needs.
            </p>
          </section>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default TermsOfService;